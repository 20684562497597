.NotFound {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5rem 0;
  contain: content;
}
.NotFound img {
  width: 100%;
  max-width: 640px;
}
