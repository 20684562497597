.Footer {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: #007057;
  color: whitesmoke;
}
.Footer p{
  margin: 0;
}
.Footer a {
  color: whitesmoke;
}
.Footer a:hover {
  color: orange;
}
.Footer-h {
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem;
  gap: 1rem;
}
.Footer-info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1.5;
  min-width: 350px;
}
.Footer-info h3 {
  font-family: "Cormorant SC", serif;
  font-weight: 600;
}
.Footer-product {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
  min-width: 350px;
  flex-wrap: wrap;
}
.Footer-contact {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
  gap: 5px;
  margin-bottom: 0;
  min-width: 350px;
}
.Footer-fastresp {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
  gap: 1rem;
  min-width: 350px;
}
.Footer-fastresp svg {
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;
  border-radius: 5px;
  background: #4dc45a;
}
.Footer-f {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgb(0, 0, 0, 0.3);
  flex-direction: column;
  gap: 0;
  text-align: center;
}
.Footer-f p {
  color: #0000004e;
  margin: 0;
  padding: 0;
  text-shadow: 1px 0px 0px rgba(255, 255, 255, 0.1);
}
.Footer-f a {
  color: #0000004e;
  text-shadow: 1px 0px 0px rgba(255, 255, 255, 0.1);
}
.Footer ul {
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-auto-flow: column;
  padding: 0 15px;
}
.Footer-product li{
  margin-bottom: 10px;
}.Footer-contact p{
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .Footer-h {
    padding: 15px;
    display: grid;
  }
  .Footer-contact {
    padding: 5px;
  }
  .Footer-info {
    padding: 5px;
  }
  .Footer-product {
    padding: 5px;
    flex: 2;
  }
  .Footer-fastresp {
    padding: 5px;
  }
  .Footer-product li::before{
    content: "▹";
    top: 6px;
    left: 0px;
    color: goldenrod;
  }
  .Footer-product ul {
    display: grid;
    list-style: none;
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 1280px) {
  .Footer-h {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
  }
  .Footer-contact {
    padding: 5px;
  }
  .Footer-info {
    padding: 5px;
  }
  .Footer-product {
    padding: 5px;
  }
  .Footer-fastresp {
    padding: 5px;
  }
}
