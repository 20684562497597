.Banner-h {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: contain;
}
.Banner-h h1 {
  font-family: "Cormorant SC", serif;
  font-weight: 600;
  font-size: 58px;
}
.Banner-splide {
  display: flex;
  width: 100%;
}
.Banner-slide {
  display: flex;
  width: 100%;
  color: whitesmoke;
}
.Banner-slide1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("../Img/banner1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 0;
  padding-top: 18%;
  padding-bottom: 18%;
}
.Banner-slide2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("../Img/banner2.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 0;
  padding-top: 18%;
  padding-bottom: 18%;
}
.Banner-slide3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("../Img/banner3.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 0;
  padding-top: 18%;
  padding-bottom: 18%;
}
.Banner-splide img{
  height: 5rem;
  filter: brightness(1.5);
}
@media screen and (max-width: 768px) {
  .Banner-splide h1{
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }
  .Banner-splide img{
    height: 5rem;
  }
}
