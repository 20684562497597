.Maps {
  width: 100%;
  height: 40vh;
}
.Maps-Info p{
  margin: 0;
}.Maps-Info span{
  font-weight: 600;
}
.Maps-Info {
  background: white;
  border: none;
  padding: 0;
}
.map-container {
  width: 100%;
  height: 40vh;
}
