.Contact {
  padding: 50px 0;
  display: flex;
  height: max-content;
  flex-wrap: wrap;
  flex-direction: column;
}
.Contact-Main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.Contact-map {
  display: flex;
  position: relative;
  width: 100%;
}
.Contact-h1 {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 5rem 10rem 0 0rem;
}
.Contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Contact-info p{
  margin: 0;
}
.Contact-h1 * {
  color: black;
}
.Contact-h1 a:hover {
  color: #4dc45a;
}
.Contact-h2 {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 5rem 0rem 5rem 10rem;
}

.Contact-h2 > form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.Contact-h2 .user {
  width: 100%;
  height: 2rem;
  padding: 0.3rem;
  outline: none;
  border: 2px solid #007007;
  border-radius: 2px;
  font-size: 16px;
}
.Contact-h2 .user:focus {
  background-color: rgb(236, 236, 236);
  border: 2px solid goldenrod;
}
textarea {
  height: 5rem !important;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
}
.row label {
  font-size: large;
  font-weight: 500;
}
.button {
  background: #007057;
  border-radius: 2px;
  border: none;
  width: 100%;
  height: 2.5rem;
  color: whitesmoke;
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}
.button:hover {
  box-shadow: inset 0 100px 0 0 goldenrod;
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}
.Contact-hide {
  display: none !important;
  transition: all 0.3s ease-in-out;
}
.Contact-done{
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50vh;
}
.Contact-done h2{
    color: #007007;
    font-size: 2rem;
    font-weight: 600;
}
.Contact-done svg{
    cursor: pointer;
    font-size: 3rem;
}
.Contact-done svg:hover{
    color: gold;
    transform: translate(0px, 3px);
    transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 768px) {
  .Contact {
    padding: 5px 5px;
  }
  .Contact-Main {
    padding: 0 5px 0 5px;
    gap: 0rem;
    flex-direction: column;
    height: 50rem;
    left: auto;
    margin: 5px 0 5px 0;
  }
  .Contact-h1 {
    padding: 0 5px 0 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .Contact-h2 {
    transform: scale(0.8);
    margin-top: -4rem;
    padding: 0 5px 0 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .Contact {
    padding: 50px 5px;
  }
  .Contact-Main {
    padding: 0 5px 0 5px;
    gap: 0rem;
    flex-direction: row;
    height: 50rem;
    left: auto;
    margin: 5px 0 5px 0;
  }
  .Contact-h1 {
    padding: 0 5px 0 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .Contact-h2 {
    transform: scale(0.8);
    margin-top: -4rem;
    padding: 0 5px 0 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
