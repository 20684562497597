.Product {
  width: 70%;
  margin: auto;
  margin-top: 80px;
}
.Product-List {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-height: 30rem;
  height: 100%;
  margin: 50px auto;
}
.Product h3 {
  display: flex;
  position: relative;
  font-size: 5vh;
}
.card {
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  transition: 0.3s ease-in-out;
  margin: 1rem;
  height: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.card:hover {
  transform: scale(1.1);
  color: orange;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px rgba(0,78,61,0.75);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,78,61,0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,78,61,0.75);
}
.card-img {
  height: 20vh;
  background-position: center center;
  border-radius: 0px;
  background-repeat: no-repeat;
  overflow: hidden;
  object-fit: none;
  object-position: center;
}
.card-img img {
  height: 100%;
  width: 100%;
}
.card img:hover {
  transform: scale(1.2);
  transition: 0.3s;
}
.card-header {
  display: flex;
  flex-direction: column;
  margin: 10px 0 5px 0;
  background: none;
  width: 100%;
  padding: 5px;
  border: none;
  align-items: center;
}
.card-header h2 {
  font-size: 18px;
  align-items: center;
  font-weight: 600;
  margin: auto;
}
.Product-Search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
}
.Product-Search input {
  width: 70%;
  margin: 0 5px;
  padding: 5px 5px;
}
.Product-Empty {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5rem 0;
  contain: content;
}
.Product-Empty span {
  font-weight: 500;
  font-size: 25px;
}
.Product-Empty a {
  display: block;
  position: relative;
  color: #999;
  top: -2rem;
  font-size: 10px;
}
.Product-Empty img {
  width: 100%;
  max-width: 640px;
}
@media screen and (max-width: 768px) {
  .Product {
    margin: 70px auto;
  }
  .Product-List {
    margin-top: 1rem;
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
  }
  .card-header p {
    margin: 4px 0;
  }
  .Product-Search input {
    width: 100%;
  }
  .card {
    margin: 2px;
    width: 100%;
  }
  .Product h3 {
    margin: 0;
  }
  .Product {
    width: 100%;
    padding: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .card {
    margin: 10px 5px;
    height: 100%;
  }
  .Product-List {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .Product h3 {
    margin: 0;
  }
  .Product {
    width: 100%;
    padding: 50px 5px;
  }
}

@media screen and (min-width: 1920px) {
  .Product-List {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .card-header h2 {
    font-size: 20px;
  }
  .card-header p {
    font-size: 16px;
  }
}
