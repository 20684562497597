.Products {
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  margin-bottom: 3rem;
  align-items: center;
}
.Products-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}
.Products-logo h2 {
  font-family: "Source Code Pro", monospace;
  font-size: 1.5rem;
  color: #777;
}
.Products-logo img {
  width: 100px;
}
.Products-container {
  display: flex;
  position: relative;
  width: 100%;
  margin: 1rem 0;
}
.Products-left {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: 1rem 2rem;
  border-right: 2px solid #171717;
}
.Products-right {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: left;
  flex: 2;
  padding: 0 5rem;
  width: 100%;
}
.Products-Details {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
}
.Products-Details td {
  border-bottom: 1px solid #ddd;
  height: 40px;
  padding: 0 1rem;
}
.Products-Details td:nth-of-type(1) {
  font-family: "Source Code Pro", monospace;
  font-weight: 500;
  width: 100px;
  overflow: hidden;
}
.Products-Details table {
  width: 100%;
  max-width: 500px;
  table-layout: auto;
}
.Products-Details tr:nth-child(even) {
  background-color: rgb(232, 255, 255);
}
.Products-Details h1 {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
  color: #004e3d;
}
.Products-Details h2 {
  font-family: "Source Code Pro", monospace;
  font-weight: 500;
  font-size: 1.5rem;
}
.Products-Details h3 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  font-size: 1.4rem;
}
.Products-Details img {
  width: 518px;
  height: 350px;
  margin: 1rem 0;
  box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 1);
}
.Products-L ul {
  display: grid;
  list-style: none;
  gap: 0px 10px;
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  list-style: none;
}
.Products-L li {
  margin-bottom: 10px;
}
.Products-L li::before {
  content: "▹";
  top: 6px;
  left: 0px;
  color: goldenrod;
}
.Products-L a {
  font-size: 1.2rem;
  color: black;
}
.Products-L ul *:hover {
  transform: translate(1px, 0px);
  color: #777;
  transition: 0.25s ease-in;
}
.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: -5px;
  background: #000;
  height: 2px;
  transition-property: left right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  right: 0;
}
.Products-left h3 {
  font-weight: 500;
  color: #004e3d;
  font-family: "Source Code Pro", monospace;
}

.Products-swiper {
  width: 80%;
  margin: auto 0;
  text-align: center;
}
.splide {
  padding-top: 0 !important;
}
.Products-card {
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  transition: 0.3s ease-in-out;
  margin: 1rem;
  min-height: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
.Products-card:hover {
  transform: translate(0px, -5px);
  color: orange;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px rgba(0, 78, 61, 0.75);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 78, 61, 0.75);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 78, 61, 0.75);
}
.Products-card-img {
  height: 15vh;
  width: 12rem;
  background-position: center center;
  border-radius: 0px;
  background-repeat: no-repeat;
  overflow: hidden;
  object-fit: none;
  object-position: center;
}
.Products-card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Products-cardcard img:hover {
  transform: scale(1.2);
  transition: 0.3s;
}
.Products-card-header {
  display: flex;
  flex-direction: column;
  margin: 10px 0 5px 0;
  background: none;
  width: 100%;
  padding: 5px;
  border: none;
  align-items: center;
}
.Products-card-header h2 {
  font-size: 1.4rem;
  align-items: center;
  font-weight: 600;
  margin: auto;
}
@media screen and (max-width: 900px) {
  .Products {
    margin: 75px auto;
    margin-bottom: 1rem;
  }
  .Products-logo {
    display: none;
  }
  .Products-container {
    margin: 1rem 0;
    width: 100%;
  }
  .Products-left {
    display: none;
  }
  .Products-right {
    padding: 10px;
  }
  .Products-Details img {
    max-width: 100%;
    width: auto;
    max-height: 400px;
  }
  .Products-swiper {
    width: 100%;
  }
  .Products-card {
    margin: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border: 0.5px solid;
  }
  .Products-card h2 {
    font-size: 14px;
  }
  .Products-card-img {
    width: 8rem;
  }
  .splide {
    padding: 5px !important;
    padding-bottom: 2em !important;
  }
}
