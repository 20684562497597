.PageLoading {
  text-align: center;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.Page {
  display: flex;
  flex-direction: column;
}
.PageNone {
  display: none;
}
.Home {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
}
.Home-AB {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.Home-A {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  border-bottom: 3px solid #005057;
}
.Home-A h2 {
  margin: 2rem 0;
  color: #005057;
}
.Home-A1 {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1 0;
  flex-direction: column;
}
.Home-A1 h1{
  max-width: 600px;
  width: inherit;
  padding: 0rem 2rem;
  margin: 0;
}
.Home-A1 p {
  font-size: 26px;
  font-family: 'EB Garamond', serif;
  line-height: 1.3;
  max-width: 600px;
  font-weight: 600;
  flex-wrap: wrap;
  padding: 0rem 2rem 2rem 2rem;
}
.Home-A3{
  flex: 1 1 0;
  margin: 0 2rem;
}
.Home-A3 h2{
  font-size: 1.2rem;
  font-weight: 600;
  color: goldenrod;
}
.Home-A2{
  width: 50%;
  flex: 1 1 0;
  margin: 0 2rem;
}
.Home-A3 p{
  font-size: 15px;
}
.Home-vcontainer {
  width: 100%;
  overflow: hidden;
  display: block;
}
.Home-A2 video {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.Home-A3 img {
  margin: 40px;
}
.Home-B {
  width: 70%;
  padding: 0;
  margin: 5rem auto;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.Home-B2 {
  display: inline-flex;
  width: 45%;
  min-width: 45%;
  margin: 10px 0 0 0;
  padding: 0;
}
.Home-B2W {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.Home-B2W h3 {
  width: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: #005057;
  padding: 2px 0;
}
.Home-B2W img {
  width: 100%;
  margin: 1rem 0;
}
.Home-B2W p {
  flex: 1;
  text-align: center;
}
.swiper-button-prev {
  display: block;
  left: -2rem;
}
.Home-SwiperC {
  margin: 2rem auto;
  width: 70%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.splide__pagination__page {
  width: 8px !important;
}
@media screen and (max-width: 768px) {
  .Home {
    width: 100%;
    margin: 0;
    padding: 0 5px;
    margin-bottom: 2rem;
  }
  .Home-A1 h1{
    padding: 0px 15px 0px;
  }
  .Home-A1 p{
    padding: 0px 15px 15px;
    font-size: 16px;
  }
  .Home-A2{
    width: auto;
  }
  .Home-AB{
    display: grid;
    grid-template-columns: auto;
  }
  .Home-B {
    width: 100%;
    margin: auto;
  }
  .Home-B2 {
    width: 100%;
    margin-top: 2rem;
  }
  .Home-B2W img {
    margin: 5px 0;
  }
  .Home-SwiperC{
    width: 100%;
  }
  .Home-B2W p {
    margin: 0;
  }
  .splide {
    padding: 10px !important;
    padding-bottom: 5em !important;
  }
  .card-header h2 {
    font-size: 14px !important;
  }
  .card {
    width: 8rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .Home {
    width: 100%;
    margin: auto;
    padding: 5px;
    margin-bottom: 2rem;
  }
  .Home-SwiperC{
    width: 100%;
  }
  .Home-A1 p{
    padding: 15px;
    font-size: 16px;
  }
  .Home-B {
    width: 100%;
    margin: auto;
  }
}
