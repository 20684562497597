.Tentang-H {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 2rem;
  width: 60%;
  font-size: 20px;
  flex-wrap: wrap;
  gap: 2rem;
}
.Tentang-banner{
  width: 100%;
  display: flex;
  align-items: center;
  height: min-content;
  align-items: center;
  justify-content: space-around;
}
.Tentang-banner > *{
  width: 40%;
}
.Tentang h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #004e3d;
  font-family: "Cormorant SC", serif;
}
.Tentang h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #004e3d;
  font-family: "Cormorant SC", serif;
}
.Tentang-S {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 2rem 0;
  gap: 1rem;
}
.Tentang-VMV {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 2rem;
  margin: 2rem 0;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  border-bottom: 5px solid #007057;
}
.Tentang-VMV img {
  width: 150px;
  margin: 1rem;
}
.Tentang-Visi {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-width: 300px;
}
.Tentang-Misi {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-width: 300px;
}
.Tentang-Value {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-width: 300px;
}
.Tentang-M {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
}
.Tentang-Img {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem 0;
  gap: 2rem;
}
.Tentang-Img img {
  width: 200px;
  max-width: min-content;
  max-height: fit-content;
}
.Tentang-L {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  gap: 3rem;
  text-align: center;
}
.Tentang-L img {
  transform: scale(0.8);
  filter: brightness(1.15);
}
.Tentang-L h2 {
  font-family: "Cormorant SC", serif;
  font-weight: 600;
  font-size: 55px;
  color: whitesmoke;
  background: #00a462bc;
  width: 100%;
  padding: 1rem;
}
@media screen and (max-width: 768px) {
  .Tentang-H {
    padding: 5px;
    width: 100%;
    text-align: center;
  }
  .Tentang-L h1 {
    display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 1440px) {
  .Tentang-H {
    padding: 5px;
    width: 80%;
  }
  .Tentang-L h1 {
    display: none;
  }
}
