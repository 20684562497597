.Nav-h {
  background: rgb(155, 251, 230);
  background: linear-gradient(
    90deg,
    rgba(155, 251, 230, 1) 0%,
    rgba(222, 241, 195, 1) 40%,
    rgba(175, 254, 223, 1) 100%
  );
  -webkit-transition: all 0.2s ease 0.2s;
  -moz-transition: all 0.2s ease 0.2s;
  -o-transition: all 0.2s ease 0.2s;
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.Nav-hidden {
  opacity: 0;
  -webkit-transition: all 0.3s ease 0.3s;
  -moz-transition: all 0.3s ease 0.3s;
  -o-transition: all 0.3s ease 0.3s;
  -ms-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
  z-index: -99;
}
.Nav-lang {
  display: flex;
  position: relative;
  justify-content: flex-end;
}
.Nav * {
  font-size: 1.2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.Nav-title h2{
  margin: 0;
  font-family: "Cormorant SC", serif;
  font-weight: 600;
}
.Nav-lang * {
  font-size: 1rem;
}
.Nav-lang svg {
  width: 1.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.Nav-logo img {
  width: 30px;
  height: auto;
}
.Nav-logo {
  display: flex;
  position: relative;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.6);
}
.nav-link {
  color: black;
  border-radius: 2rem;
  margin-right: 1rem;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 1);
  text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.6);
}
